"use client";
import JobCardPlaceholder from "@/app/_components/share/JobCardPlaceholder";
import { JOB_INFORMATION_DETAIL_PATH } from "@/services/constants/routerPath";
import { Box, Center, Stack } from "@chakra-ui/react";
import { get } from "lodash";
import Link from "next/link";
import { Autoplay, Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const JobAdsSlider = ({ data }) => {
  return (
    <Stack w="full" gap={6}>
      <Swiper
        style={{ width: "100%" }}
        grid={{ rows: 4, fill: "row" }}
        slidesPerView={3}
        spaceBetween={4}
        modules={[Autoplay, Grid, Navigation]}
        loop={true}
        loopAddBlankSlides={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{ enabled: true }}
        breakpoints={{
          768: {
            slidesPerView: 3,
            navigation: { enabled: true },
          },
          1280: {
            slidesPerView: 3,
            navigation: { enabled: true },
          },
        }}
      >
        {data.map((categoryJobItem, index) => {
          const { job, photos } = categoryJobItem;
          const isPlaceholder = !job._id; // Kiểm tra xem là placeholder hay dữ liệu thực tế
          const image =
            get(photos?.[0], "url") ??
            get(job, "recruitmentNotice[0].url") ??
            get(job, "corporate.logo.url");

          return (
            <SwiperSlide key={index} h="full">
              {isPlaceholder ? (
                <Center aspectRatio="380 /280">
                  <JobCardPlaceholder minH={0} />
                </Center>
              ) : (
                <Link href={JOB_INFORMATION_DETAIL_PATH + get(job, "_id")}>
                  <Box
                    aspectRatio="380 / 280"
                    rounded="xl"
                    bgPosition="center"
                    bgSize="cover"
                    bgColor="faded"
                    bgImage={image}
                  ></Box>
                </Link>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Stack>
  );
};

export default JobAdsSlider;
