"use client";
import DefaultBannerImage from "@/app/_components/share/DefaultBannerImage";
import { getSideAdsList } from "@/services/api/ads";
import { Box, HStack, Image, Show, Skeleton, Stack } from "@chakra-ui/react";
import { get, range } from "lodash";
import Link from "next/link";
import { useQuery } from "react-query";
import "swiper/css/navigation";
import { Autoplay, Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function SideAdsStack(props) {
  const { isLoading, data } = useQuery("sideAds", () => getSideAdsList());

  return (
    <Box as="aside" w={{ base: "full", lg: 300 }} gap={3} {...props}>
      {isLoading ? (
        <>
          <Show above="xl">
            <Stack>
              {range(0, 2, 1).map((index) => (
                <Skeleton key={index} height={560} w={300} borderRadius={6} />
              ))}
            </Stack>
          </Show>
          <Show above="lg" below="xl">
            <Skeleton height={300} w={300} borderRadius={6} />
          </Show>
          <Show below="lg">
            <HStack w="full">
              {range(0, 2, 1).map((index) => (
                <Skeleton key={index} height={300} w={300} borderRadius={6} />
              ))}
            </HStack>
          </Show>
        </>
      ) : (
        <Swiper
          style={{ width: "100%" }}
          grid={{ rows: 1, fill: "row" }}
          slidesPerView={2}
          spaceBetween={8}
          modules={[Autoplay, Grid, Navigation]}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={false}
          breakpoints={{
            992: {
              slidesPerView: 1,
              grid: {
                rows: 1,
                fill: "row",
              },
              navigation: true,
              spaceBetween: 16,
            },
            1280: {
              slidesPerView: 1,
              grid: {
                rows: 2,
                fill: "row",
              },
              navigation: true,
              spaceBetween: 16,
            },
          }}
        >
          {data?.map((ads, index) => (
            <SwiperSlide key={index}>
              {get(ads, "image") || get(ads, "photos[0]") ? (
                <Link
                  key={ads._id}
                  href={
                    ads.link.startsWith("http")
                      ? ads.link
                      : `https://${ads.link}`
                  }
                  target="_blank"
                >
                  <Image
                    src={get(ads, "photos[0].url") ?? get(ads, "image.url")}
                    alt="Side ads thumb"
                    bgColor="gray.400"
                    borderRadius={6}
                    border="0.5px solid #E8E8E8"
                    objectFit="cover"
                    minW={{ lg: 300 }}
                    w={{ base: "full", lg: 300 }}
                    h={{ base: 300, lg: 424 }}
                  />
                </Link>
              ) : (
                <Box
                  key={index}
                  w={300}
                  h={424}
                  borderRadius={6}
                  overflow="hidden"
                >
                  <DefaultBannerImage />
                </Box>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
}
