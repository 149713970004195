import ApiInstance from "@/services/api/api.utils";
import { AdsBannerPosition } from "@/services/constants/global";
import { shuffleArray } from "@/services/utils/common";
import { get } from "lodash";

export const getAdsBannerList = async (position) => {
  const res = await ApiInstance.get("/ads-banners", {
    params: { position: position },
  });
  if (position === AdsBannerPosition.LANDING) {
    while (get(res, "docs.length") < 2) res.docs.push({ _id: "" });
  }
  return shuffleArray(res.docs);
};

export const getHomepageAdsBannerList = async () => {
  // const [mainBigBanner, mainRightBanner] = await Promise.all([
  //   getAdsBannerList(AdsBannerPosition.MAIN_BIG),
  //   getAdsBannerList(AdsBannerPosition.MAIN_RIGHT),
  // ]);
  // if (!mainBigBanner.length) mainBigBanner.push({ _id: "" });
  // while (mainRightBanner.length < 2) mainRightBanner.push({ _id: "" });
  // return { mainBigBanner, mainRightBanner };
  const res = await getAdsBannerList(AdsBannerPosition.MAIN_BIG);
  while (res.length < 3) res.push({ _id: "" });
  return shuffleArray(res);
};

export const getSideAdsList = async (
  position = AdsBannerPosition.SLIDE_RIGHT
) => {
  const res = await ApiInstance.get("/ads-banners", {
    params: { position: position },
  });
  while (get(res, "docs.length") < 4) res.docs.push({ _id: "" });
  return res.docs;
};

export const postRequestBannerAds = async (
  payload = {
    companyName: "",
    startPeriod: "",
    dayPeriod: "",
    inquiryContent: "",
    image: "",
    adsPosition: "",
  }
) => {
  const res = await ApiInstance.post("/ads-requests/banner", payload);
  return res;
};

export const postRequestJobAds = async (
  payload = {
    adsPosition: "",
    companyName: "",
    recruitmentTitle: "", // use with role headhunter
    inquiryContent: "",
    job: "",
    startPeriod: "",
    dayPeriod: 0,
    photos: [],
  }
) => {
  const res = await ApiInstance.post("/ads-requests/job", payload, {
    "Content-Type": "multipart/form-data",
  });
  return res;
};

export const postSendQuotation = async (
  payload = {
    adsPosition: "655f08b92b1bb39bb98cfda9",
    startPeriod: "2023-11-30",
    dayPeriod: 3,
  }
) => {
  const res = await ApiInstance.post("/ads-requests/send-quotation", payload);
  return res;
};

export const getListBannerAdsPositions = async (page = 1, limit = 10) => {
  const res = await ApiInstance.get("/ads-positions/banners", {
    params: {
      page,
      limit,
    },
  });
  return res;
};

export const getListJobAdsPositions = async (page = 1, limit = 10) => {
  const res = await ApiInstance.get("/ads-positions/jobs", {
    params: {
      page,
      limit,
    },
  });
  return res;
};

export const patchJobAdsRequest = async (adsRequestId, payload) => {
  const res = await ApiInstance.patch(
    `/ads-requests/jobs/${adsRequestId}`,
    payload,
    {
      "Content-Type": "multipart/form-data",
    }
  );
  return res;
};

export const cancelJobAdsRequest = async (adsRequestId) => {
  const res = await ApiInstance.post(
    `/ads-requests/jobs/${adsRequestId}/cancel`
  );
  return res;
};

export const patchBannerAdsRequest = async (adsRequestId, payload) => {
  const res = await ApiInstance.patch(
    `/ads-requests/banners/${adsRequestId}`,
    payload,
    {
      "Content-Type": "multipart/form-data",
    }
  );
  return res;
};

export const deleteAdsRequest = async (payload) => {
  const res = await ApiInstance.delete(`/ads-requests/${payload}`);
  return res;
};

export const getAdsBannerHistory = async (
  params = { page: 1, limit: 100, status: "", type: "", keyword: "" }
) => {
  const res = await ApiInstance.get("/ads-requests", { params });
  return res;
};

export const getAdsJobsHistory = async (
  params = { page: 1, limit: 100, status: "", type: "", keyword: "" }
) => {
  const res = await ApiInstance.get("/ads-requests", { params });
  return res;
};
