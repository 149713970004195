"use client";
import CategoryJobCard from "@/app/_components/share/CategoryJobCard";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Collapse,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function ExpandJobGrid({ data }) {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  const defaultList = data.filter((_, index) => index < 6);
  const showList = data.filter((_, index) => index >= 6);

  return (
    <>
      <Show above="lg">
        <Stack align="center" w="full" gap={4}>
          <SimpleGrid columns={3} gap={4} w="full">
            {defaultList?.map((adsJob, index) => {
              const { job, photos } = adsJob;

              return (
                <CategoryJobCard
                  key={job._id + index}
                  id={job._id}
                  corporateId={get(job, "corporate._id")}
                  imageUrl={
                    get(photos?.[0], "url") ||
                    get(job, `recruitmentNotice[0].url`) ||
                    get(job, "corporate.logo.url")
                  }
                  logoUrl={
                    get(job, "corporate.logo.url") || get(job, "corporate.logo")
                  }
                  subTitle={job.companyName}
                  title={job.recruitmentName}
                />
              );
            })}
          </SimpleGrid>
          <Collapse in={isOpen} animateOpacity style={{ width: "100%" }}>
            <SimpleGrid columns={3} gap={4} w="full">
              {showList?.map((adsJob, index) => {
                const { job, photos } = adsJob;

                let indexPhoto = 0;
                if (get(job, "recruitmentNotice[0].url")) indexPhoto = 0;
                return (
                  <CategoryJobCard
                    key={job._id + index + 6}
                    id={job._id}
                    corporateId={get(job, "corporate._id")}
                    imageUrl={
                      get(photos?.[0], "url") ||
                      get(job, `recruitmentNotice[${indexPhoto}].url`) ||
                      get(job, "corporate.logo.url")
                    }
                    logoUrl={
                      get(job, "corporate.logo.url") ||
                      get(job, "corporate.logo")
                    }
                    subTitle={job.companyName}
                    title={job.recruitmentName}
                  />
                );
              })}
            </SimpleGrid>
          </Collapse>
          {data.length > 6 && (
            <Button
              as={Stack}
              variant="ghost"
              onClick={onToggle}
              gap={0}
              cursor="pointer"
            >
              {isOpen ? (
                <>
                  <ChevronUpIcon />
                  <Text>{t("Show less")}</Text>
                </>
              ) : (
                <>
                  <Text>{t("Show more")}</Text>
                  <ChevronDownIcon />
                </>
              )}
            </Button>
          )}
        </Stack>
      </Show>
      <Show below="lg">
        <Swiper
          style={{ width: "100%" }}
          slidesPerView={1}
          spaceBetween={16}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
          }}
        >
          {data.map((adsJob, index) => {
            const { job } = adsJob;
            let indexPhoto = 0;
            if (get(job, "recruitmentNotice[0].url")) indexPhoto = 0;

            return (
              <SwiperSlide key={job._id + index}>
                <CategoryJobCard
                  id={job._id}
                  corporateId={get(job, "corporate._id")}
                  imageUrl={
                    get(job, `recruitmentNotice[${indexPhoto}].url`) ||
                    get(job, "corporate.logo.url")
                  }
                  logoUrl={
                    get(job, "corporate.logo.url") || get(job, "corporate.logo")
                  }
                  subTitle={job.companyName}
                  title={job.recruitmentName}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Show>
    </>
  );
}
